import { useRouter } from 'next/router'
import Link from 'next/link'

import {
    Pane,
    Heading,
    Button,
    Paragraph,
    ChevronRightIcon,
} from 'evergreen-ui';

import Dialog from './dialog'

const Routes = require("../utils/routes");

export default function Prompts(props) {

    if (props.choice == "solutions") {
        return (
            <Link href={Routes.links.solutions} >
                <Pane className="box-shadow-muted-soft p-2 bg-brand hero-solutions-prompt" role="button">
                    <Paragraph size={300} className="w-auto mt-2 mb-1 text-white">
                        Explore Solutions <ChevronRightIcon />
                    </Paragraph>
                </Pane>
            </Link>
        )
    }

    if (props.choice == "demo") {
        return (<Dialog />)
    }


    return (
        <Link href={Routes.links.kn_focused} >
            <Pane className="box-shadow-muted-soft p-2 hero-intro-box text-brand" role="button">
                <Paragraph size={300} className="w-100 mt-2 mb-1 text-brand">
                    Knowledge focused, what does this mean? <ChevronRightIcon />
                </Paragraph>
            </Pane>
        </Link>
    )
}
