import Head from 'next/head'
import Link from 'next/link'
import styled from 'styled-components'

import {
  Pane,
  Heading,
  Paragraph,
  Avatar
} from 'evergreen-ui';

import {
  ReaderIcon,
  CubeIcon,
  MixIcon,
  ArchiveIcon,
  CardStackIcon,
} from '@radix-ui/react-icons';

export default function Feature() {
  let features = [
    {
      icon: <CubeIcon className="mr-2 mb-1" />,
      title: "Learning Management",
      about: "Bring all your knowledge content together under one roof, organized and structured, welcomed from any type of source, combined to learning components."
    },
    {
      icon: <MixIcon className="mr-2 mb-1" />,
      title: "Learning Distribution",
      about: "Distribute your boxes via ports into your templates of choice - choose a template per what suites your goal and customize to your brand."
    },
    {
      icon: <CardStackIcon className="mr-2 mb-0" />,
      title: "Learning Experience",
      about: "Serve your knowledge via your learning portals or use our sdk and component tooling to render as you please anywhere on the internet."
    },
  ];

  return (
    <Pane className="row align-items-center">
      {features.map((feature, index) => {
        return (
          <Pane className="col-lg-4 col-md-12 col-sm-12 h-100 pt-3" key={index}>
            <Pane className="row pl-2"  >
              <Pane className="col-12"  >
                <Heading size={400} className="w-100 mt-2 text-brand d-flex align-items-center">
                  {feature.icon} {feature.title}
                </Heading>
                <Paragraph size={300} className="w-100 mt-2 text-white"> {feature.about}  </Paragraph>
              </Pane>
            </Pane>
          </Pane>
        )
      })}
    </Pane>
  )
}