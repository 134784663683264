import React, { useState, useEffect } from 'react';

import Head from 'next/head'

import Header from '../components/header'
import Footer from '../components/footer'
import DemoSignup from '../components/demo'
import Features from '../components/features'
import Prompts from '../components/prompts'

import Testimonial from '../components/testimonial'

import {
    Pane,
    Paragraph,
    Heading,
    Button
} from 'evergreen-ui';

import {
    ArrowRightIcon,
    CircleIcon,
    GlassIcon,
    ChevronRightIcon
} from 'evergreen-ui';

import Ticker from 'react-ticker'

import { NetlifyForm, Honeypot } from 'react-netlify-forms'
import TextTransition, { presets } from "react-text-transition";

const Routes = require("../utils/routes");
const config = require("../utils/config");

export default function Home() {

    let featureIndex = 0;
    let TEXTS = ["Everlasting", "Continuous", "Painful", "Repetitive"];
    let story = {
        create: [
            {
                title: "Model your learning experience how you want it to be",
                image: "/images/new/create.png",
                summary: "Model your learning with a combination video, text, files and any type of engagement component you want(quizes, certifications etc) from our growing library of components",
            },
            {
                title: "Model your learning experience how you want it to be",
                image: "/images/new/create.png",
                summary: "Model your learning with a combination video, text, files and any type of engagement component you want(quizes, certifications etc) from our growing library of components",
            },
            {
                title: "Model your learning experience how you want it to be",
                image: "/images/new/create.png",
                summary: "Model your learning with a combination video, text, files and any type of engagement component you want(quizes, certifications etc) from our growing library of components",
            },
        ],
        port: [
            {
                title: "Model your learning experience how you want it to be",
                image: "/images/new/port.png",
                summary: "Model your learning with a combination video, text, files and any type of engagement component you want(quizes, certifications etc) from our growing library of components",
            },
            {
                title: "Model your learning experience how you want it to be",
                image: "/images/new/port.png",
                summary: "Model your learning with a combination video, text, files and any type of engagement component you want(quizes, certifications etc) from our growing library of components",
            },
            {
                title: "Model your learning experience how you want it to be",
                image: "/images/new/port.png",
                summary: "Model your learning with a combination video, text, files and any type of engagement component you want(quizes, certifications etc) from our growing library of components",
            },
        ],
        report: [
            {
                title: "Model your learning experience how you want it to be",
                image: "/images/new/report.png",
                summary: "Model your learning with a combination video, text, files and any type of engagement component you want(quizes, certifications etc) from our growing library of components",
            },
            {
                title: "Model your learning experience how you want it to be",
                image: "/images/new/report.png",
                summary: "Model your learning with a combination video, text, files and any type of engagement component you want(quizes, certifications etc) from our growing library of components",
            },
            {
                title: "Model your learning experience how you want it to be",
                image: "/images/new/report.png",
                summary: "Model your learning with a combination video, text, files and any type of engagement component you want(quizes, certifications etc) from our growing library of components",
            },
        ],
    }
    const [story1Index, setStory1Index] = useState(0);

    const [getFeatureIndex, setFeatureIndex] = useState(0);
    const [index, setIndex] = React.useState(0);
    const [playing, setPlaying] = React.useState(false);

    useEffect(() => {
        const intervalId = setInterval(() =>
            setIndex(index => index + 1),
            3000 // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);

    function PlayVideo() {
        let myVideo = document.getElementById("autoPlay");

        if (myVideo.paused) {
            myVideo.play();
            setPlaying(true);
        }
        else {
            myVideo.pause();
            setPlaying(false);
        }
    }

    useEffect(() => {
        let myVideo = document.getElementById("autoPlay");
        // myVideo.pause();
        // setPlaying(false);
    }, []);

    useEffect(() => {
        const featureLoop = setInterval(() => {
            if (featureIndex == 3) {
                featureIndex = 0;
                setFeatureIndex(featureIndex);
            } else {
                featureIndex = featureIndex + 1;
                setFeatureIndex(featureIndex);
            }
        },
            4000 // every 4 seconds
        );
        return () => clearTimeout(featureLoop);
    }, []);

    return (
        <Pane className="page">

            <Head>
                <link rel="shortcut icon" href={config.Product.logo} type="image/x-icon" />
                <title>{config.Product.title}</title>

                <meta name="title" content={config.Product.title} />
                <meta name="description" content={config.Product.description} />

                <meta property="og:type" content="website" />
                <meta property="og:url" content={config.Product.url} />
                <meta property="og:title" content={config.Product.title} />
                <meta property="og:description" content={config.Product.description} />
                <meta property="og:image" content={config.Product.cover} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={config.Product.url} />
                <meta property="twitter:title" content={config.Product.title} />
                <meta property="twitter:description" content={config.Product.description} />
                <meta property="twitter:image" content={config.Product.cover} />
            </Head>

            <Pane className="bg-hero-blue pb-5">
                <Header />

                <Pane className="w-100 h-100 pt-5 pb-5">
                    <Pane className="container">
                        <Pane className="row align-items-center pt-3 mt-2 mb-5 h-100">
                            <Pane className="col-lg-7 col-md-12 col-sm-12 text-left">
                                <Prompts choice="demo" />
                                <Prompts choice="solutions" />

                                <Heading className="mt-2 hero-title text- text-white" size={800}>
                                    Learnir helps you deliver the best of learning experiences from management to delivery
                                </Heading>

                                <Paragraph className="mt-2 mb-3 hero-sub-title w-100 text- text-white" size={400}>
                                    Structure and organize your knowledge content, ready for your people to Learn something new.™
                                </Paragraph>

                                <DemoSignup />

                                <Ticker className="border">
                                    {({ index }) => (<><Paragraph size={300} className="w-auto h-100 text-white mt-4 mr-5">
                                        Education teams use Learnir to train customers, employees, partners on skills and knowledge content
                                        ready to increase product adoption, smooth employee onboarding,
                                        decrease support tickets and all the good things increased knowledge brings.
                                    </Paragraph></>)}
                                </Ticker>
                            </Pane>

                            <Pane className="col-lg-5 col-md-12 col-sm-12  animate__animated animate__pulse">
                                <Paragraph className="mt-2 mb-3 hero-sub-title w-100 text- text-white" size={300}>
                                    Interactions components? - try this quiz component directly here below
                                </Paragraph>
                                <Pane className="border rounded learnir-exp-module-parent box-shadow-muted-soft">
                                    <learnir-exp-module
                                        component={"332807090772902482"}
                                        consumer={"328136497833181780"}
                                        box={""}
                                        port_key={"329936155895136841"}
                                    ></learnir-exp-module>
                                </Pane >

                            </Pane>
                        </Pane>
                    </Pane >

                    <Pane className="mt-5 pt-4 container">
                        <Features />
                    </Pane >

                </Pane >
            </Pane >

            <Pane className="w-100">
                <Pane className="w-100">
                    <Pane className="container">
                        <Pane className="w-100" id="features" >
                            <Pane className="w-100" >
                                <Pane className="row align-items-top pt-5 mt-5">
                                    <Pane className="col-lg-12 col-md-12 col-sm-12 text-center h-100 mb-4" >
                                        <Heading className="text-black" size={800} > Start your learning experience with a content box </Heading>
                                        <Paragraph size={400} className="mt-2 text-black">Bring your learning experience content into sections & categories</Paragraph>
                                    </Pane>
                                </Pane>

                                <Pane className="row align-items-center mt-3 mb-5 pt-2 pb-2  mx-auto border bg-white rounded p-4">
                                    <Pane className="col-lg-6 col-md-12 col-sm-12 " >
                                        <img src={story.create[0].image} className="h-auto mx-auto mt-10 w-100 rounded" />
                                    </Pane>
                                    <Pane className="col-lg-6 col-md-12 col-sm-12" >
                                        <Pane className="w-90 p-lg-4" >
                                            <Pane className="w-100 p-2 pb-3 pt-3" >
                                                <Heading size={300} className="bg-brand p-0 text-white text-center rounded-30px" width="50px"> Create </Heading>
                                            </Pane>
                                            <Pane className="w-100 p-4 bg-yellow-glass rounded" >
                                                <Heading size={400} className=""> Support all types of learning media  </Heading>
                                                <Paragraph className="mt-2" size={300}>
                                                    Setup your learning sections with media of all types. Video, audio, files, etc and add new fields as needed to support
                                                    further learning use-case ideas.
                                                </Paragraph>
                                            </Pane>
                                            <Pane className="w-100 p-4" >
                                                <Heading size={400} className=""> Include interactive box components  </Heading>
                                                <Paragraph className="mt-2" size={300}>
                                                    Components is an ever growing toolset for interactive learning experiences. Popular of which includes quizzes, certifications, website embeds, leaderboards etc.
                                                </Paragraph>
                                            </Pane>
                                            <Pane className="w-100 p-4" >
                                                <Heading size={400} className=""> Add new fields as needed  </Heading>
                                                <Paragraph className="mt-2" size={300}>
                                                    Unlimit your learning boxes by setting all the information you need for your learning box completely. Popular of these includes author information, code examples etc.
                                                </Paragraph>
                                            </Pane>
                                        </Pane>
                                    </Pane>
                                </Pane>

                                <Pane className="row align-items-center mt-5 mb-5 pt-2 pb-2  mx-auto border bg-white rounded p-4">
                                    <Pane className="col-lg-6 rounded col-md-12 col-sm-12" >
                                        <Pane className="w-90 p-lg-4" >
                                            <Pane className="w-100 p-2 pb-3 pt-3" >
                                                <Heading size={300} className="bg-brand p-0 text-white text-center rounded-30px" width="40px"> Port </Heading>
                                            </Pane>

                                            <Pane className="w-100 p-4 bg-red-glass rounded" >
                                                <Heading size={400} className=""> Done? setup your boxes for going live</Heading>
                                                <Paragraph className="mt-2" size={300}>
                                                    Ports allow you to establish where your boxes will be going out to, where and how.
                                                </Paragraph>
                                            </Pane>
                                            <Pane className="w-100 p-4" >
                                                <Heading size={400} className=""> Deploy via templates - enough templates  </Heading>
                                                <Paragraph className="mt-2" size={300}>
                                                    Templates allow you to serve your learning experiences your way, clone a learning portal of choice and customize it fully to your needs. On brand, the default, you choose.
                                                </Paragraph>
                                            </Pane>
                                            <Pane className="w-100 p-4" >
                                                <Heading size={400} className=""> Custom build from the ground up </Heading>
                                                <Paragraph className="mt-2" size={300}>
                                                    Learnir provides sdks and a components-interaction-module for you to build your learning as needed, whether learning via InProduct or via Slack bots, you choose.
                                                </Paragraph>
                                            </Pane>
                                        </Pane>
                                    </Pane>
                                    <Pane className="col-lg-6 col-md-12 col-sm-12" >
                                        <img src={story.port[0].image} className="h-auto mx-auto mt-10 w-100 rounded" />
                                    </Pane>

                                </Pane>

                                <Pane className="row align-items-center mt-5 mb-5 pt-2 pb-2  mx-auto border bg-white rounded p-4">
                                    <Pane className="col-lg-6 col-md-12 col-sm-12" >
                                        <img src={story.report[0].image} className="h-auto mx-auto mt-10 w-100 rounded" />
                                    </Pane>
                                    <Pane className="col-lg-6 col-md-12 col-sm-12" >

                                        <Pane className="w-90 p-lg-4" >
                                            <Pane className="w-100 p-2 pb-3 pt-3" >
                                                <Heading size={300} className="bg-brand p-0 text-white text-center rounded-30px" width="60px"> Report </Heading>
                                            </Pane>

                                            <Pane className="w-100 p-4 bg-pink-glass rounded" >
                                                <Heading size={400} className=""> Know the impact of your learning experience  </Heading>
                                                <Paragraph className="mt-2" size={300}>
                                                    Get insights by default from our templates and add as needed to connect learning back to your business
                                                </Paragraph>
                                            </Pane>
                                            <Pane className="w-100 p-4" >
                                                <Heading size={400} className=""> Enjoy 3+ categories of learning experience data points </Heading>
                                                <Paragraph className="mt-2" size={300}>
                                                    Combine insights directly from your learning portal sites, learning interactions data, and learning content data to see if your on track to achieve your learning goals.
                                                </Paragraph>
                                            </Pane>
                                            <Pane className="w-100 p-4" >
                                                <Heading size={400} className=""> Get streamlined reports & build your own reports as needed  </Heading>
                                                <Paragraph className="mt-2" size={300}>
                                                    Learnir automatically summaries your learning insight categories and allows you to expand, customize and tap into statistical templates to generate further insights into learner behaviours.
                                                </Paragraph>
                                            </Pane>
                                        </Pane>
                                    </Pane>
                                </Pane>
                            </Pane>
                        </Pane>
                    </Pane >
                </Pane >
            </Pane >

            <Pane className="w-100">
                <Pane className="container">
                    <Pane className="row align-items-center mt-2 mb-5 pt-5 pb-5  mx-auto border bg-blue rounded p-4" height="auto">
                        <Pane className="col-lg-7 col-md-12 col-sm-12 mx-auto" >
                            <Pane className="w-100 text-center p-lg-5 rounded" >
                                <Heading size={900} className="text-white"> Know the impact of your learning experience  </Heading>
                                <Paragraph className="mt-2 text-white" size={400}>
                                    Stay ahead of the times by tapping into an ever growing list  of tools from actual interactive learning components to functional in the background components,
                                    all the way to the many ways to present your learning experiences.
                                </Paragraph>
                                <a href={Routes.links.external.console.signup}>
                                    <Button className="mt-2 text-black p-3 bg-white">
                                        Get creating
                                    </Button>
                                </a>
                            </Pane>
                        </Pane>
                    </Pane>
                </Pane>
            </Pane>


            <Pane className="container pt-5 pb-4">
                <Footer />
            </Pane>

        </Pane >
    )
}
