const Airtable = require('airtable')
const utils = require('../utils');

const AirtableClient = new Airtable({ apiKey: 'key4tezVgaJ6QEGOn' }).base('appiBn7w7Iojqs6xN');


function Blog(data) {
    return new Promise((resolve, reject) => {
        AirtableClient("Blog").create([{
            "fields": {
                "email": data.email,
                "page": data.page,
            }
        }]).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        })
    });
}
function json(url) {
    return fetch(url).then(res => res.json());
}

function Demo(data) {
    return new Promise((resolve, reject) => {
        let apiKey = '21723d66db7520151016fe13cbd530a3be1bdfee7a31d44df8dba033';
        json(`https://api.ipdata.co?api-key=${apiKey}`).then(ip_data => {
            AirtableClient("Demo").create([{
                "fields": {
                    "email": data.email,
                    "page": data.page,
                    "ip_data": JSON.stringify(ip_data),
                }
            }]).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        });
    });
}

function Visitor(data) {
    return new Promise((resolve, reject) => {
        let apiKey = '21723d66db7520151016fe13cbd530a3be1bdfee7a31d44df8dba033';
        json(`https://api.ipdata.co?api-key=${apiKey}`).then(ip_data => {
            AirtableClient("Visitor").create([{
                "fields": {
                    "email": data.email,
                    "page": data.page,
                    "ip_data": JSON.stringify(ip_data),
                }
            }]).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        });
    });
}

let operations = {
    blog: Blog,
    demo: Demo,
    visitor: Visitor,
}

export { operations }
