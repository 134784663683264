import React, { useState, useEffect } from 'react';
import Head from 'next/head'
import Link from 'next/link'

import {
    Pane,
    Paragraph,
    Heading,
    Button,
    Dialog
} from 'evergreen-ui';

import { Cross1Icon, HamburgerMenuIcon } from '@radix-ui/react-icons'

const axios = require('axios');
const AirtableBackend = require('../utils/backend/airtable');
const utils = require('../utils/utils');

export default function Demo() {

    const [getInfo, setInfo] = useState({ email: '' });
    const [show, setShow] = useState(false);

    function HandleInput(e) {
        let name = e.target.name;
        let value = e.target.value;
        setInfo({ ...getInfo, [name]: value });
    }

    function Submit() {
        let data = getInfo;
        if (utils.functions.isEmail(data.email)) {
            data.page = window && window.location.href;
            AirtableBackend.operations.demo(data).then((response) => {
                setInfo({ ...getInfo, email: "" });
                setShow(true);
            }).catch((error) => {
                utils.functions.notify(2, "Saving your email for automatic email follow-up failed, Haah - the internet, you still get the demo though, so here you go.");
                setShow(true);
            });
        } else {
            utils.functions.notify(2, "Hello you, an email is required for this - it will be used to send you an automatic follow up guide on experiencing Learnir for yourself.")
        }
    };

    function DemoDialog(props) {
        return (
            <Pane className="" >
                <Dialog
                    isShown={show}
                    title="Demo Video"
                    intent="danger"
                    onCloseComplete={() => setShow(false)}
                    hasHeader={false}
                    hasFooter={false}
                    width={"auto"}
                    height={"500px"}
                    className="menu-dialog bg-black"
                    preventBodyScrolling={true}>
                    <Pane className="pt-3 pb-3" height="auto" width="auto">
                        {/* <iframe src="https://iframe.mediadelivery.net/embed/30691/47f54639-e4d6-4101-a82e-4f933fb084fa?autoplay=true&preload=false"
                            loading="lazy"
                            className="demo-video-box rounded"
                            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                            allowFullScreen={true}>
                        </iframe> */}
                        <Paragraph size={400} className="mt-2 mb-3 text-black">
                            Hey, a better quality demo video is currently in the works, we will forward it to your email as it comes.
                        </Paragraph>
                    </Pane>
                </Dialog>
            </Pane>
        )
    }
    // enter email, 
    // validate email entry
    // store the email into our database
    // send user an email after 10mins telling them what is the best way to experience Learnir - via knock
    // show the demo dialog box

    return (
        <Pane className="row mx-auto align-items-center demo-box" >
            {DemoDialog()}
            <Pane className="col-lg-8 col-md-12 col-sm-12 p-0 pr-lg-3" >
                <input
                    placeholder="Email"
                    type="email"
                    className="w-100 bg-white text-black"
                    name="email"
                    value={getInfo.email}
                    onChange={HandleInput} >
                </input>
            </Pane>
            <Pane className="col-lg-4 col-md-12 col-sm-12 p-0" >
                <Button padding={23} onClick={Submit} className="align-items-center demo-box-button bg-brand text-white">
                    Watch Demo
                </Button>
            </Pane>
        </Pane>
    )
}
