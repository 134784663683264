import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router'

import Head from 'next/head'
import Link from 'next/link'

import {
    Badge,
    Pane,
    SidebarTab,
    Tablist,
    Table,
    Button,
    Paragraph,
    Tab,
    Heading,
    Checkbox,
    Pill,

    RadioGroup,
    Spinner,

    Text,
    Strong,
    Alert,
    Avatar,
    Dialog,
    WarningSignIcon,
} from 'evergreen-ui';

import ReactPlayer from 'react-player'


export default function DemoVideoPrompt(props) {
    return (
        <Dialog
            isShown={false}
            title="Demo Video"
            intent="danger"
            onCloseComplete={() => console.log(false)}
            hasHeader={false}
            hasFooter={false}
            confirmLabel="Yes close"
            width={"auto"}
            height="400px"
            className="p-0"
            preventBodyScrolling={true}>
            <Pane className="text-center h-auto w-100 pt-4 pb-4" >
                {/* <ReactPlayer
                    controls={true}
                    url={"https://video.bunnycdn.com/play/30691/5cc31d3c-8b92-4093-aff0-e948c4c410e6"}
                    className="w-100 h-auto rounded portal-video-box"
                    config={{
                        file: {
                            attributes: {
                                onContextMenu: e => e.preventDefault(),
                                controlsList: 'nodownload'
                            }
                        }
                    }}
                /> */}
                    <iframe src="https://iframe.mediadelivery.net/embed/30691/5cc31d3c-8b92-4093-aff0-e948c4c410e6?autoplay=true"
                        loading="lazy" style={{"height": "400px", width: "700px" }}
                        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                        allowFullScreen="true"></iframe>

            </Pane>
        </Dialog>
    )
}

