import { useRouter } from 'next/router'
import Link from 'next/link'

import {
  Pane,
  Heading,
  Button,
  Paragraph
} from 'evergreen-ui';

const Routes = require("../utils/routes");

export default function Testimonial(props) {

  if (props.choice == "investomedia") {
    return (
      <Pane className="row mt-3 p-3 mx-auto align-items-center">
        <Pane className="col-1 p-0">
          <img src="/images/profile.jpg" className="rounded-circle" height="50px" width="50px" />
        </Pane>
        <Pane className="col-11 pl-3">
          <Paragraph size={400} className="">
            "I wanted a place to store and distribute all our partner learning content and Learnir made that possible and super easy!"<Heading size={400} className="">-Investomedia</Heading>
          </Paragraph>
        </Pane>
      </Pane>
    )
  }

  if (props.choice == "henry") {
    return (
      <Pane className="row mt-3 p-3 mx-auto align-items-center">
        <Pane className="col-1 p-0">
          <img src="/images/henry.png" className="rounded-circle" height="50px" width="50px" />
        </Pane>
        <Pane className="col-11 pl-3">
          <Paragraph size={400} className="">
            "Learnir is the airtable of learning experiences, for any structured learning experience you dream up,
            Learnir helps you model it out for your learners. Explore the solutions page to see a collective on how we solve for this."<Heading size={400} className="">-Henry Asante</Heading>
          </Paragraph>
        </Pane>
      </Pane>
    )
  }

  if (props.choice == "henry-2") {
    return (
      <Pane className="row mt-3 p-3 mx-auto align-items-center">
        <Pane className="col-1 p-0">
          <img src="/images/henry.png" className="rounded-circle" height="50px" width="50px" />
        </Pane>
        <Pane className="col-11 pl-4">
          <Paragraph size={400} className="">
            "You can model everything related with the final outcome of your learning experience. Taking things from there
            comes from asking, what is the best way for my learnir's to take in this content?"<Heading size={400} className="">-Henry Asante</Heading>
          </Paragraph>
        </Pane>
      </Pane>
    )
  }

  return (
    <Pane className="row mt-3 p-3 mx-auto align-items-center">
      <Pane className="col-1 p-0">
        <img src="/images/jade.jpg" className="rounded-circle" height="50px" width="50px" />
      </Pane>
      <Pane className="col-11 pl-3">
        <Paragraph size={400} className="">
          "Best thing to have happened to us in managing our knowledge content, now we only focus on building the best learning experiences only!"
          <Heading size={400} className="">-Nvidia</Heading>
        </Paragraph>
      </Pane>
    </Pane>
  )
}
